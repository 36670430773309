<template>
  <div>
    <div class="card card-statistics"><!----><!---->
      <div class="card-body statistics-body" style="margin-bottom: -30px; margin-top: -10px; ">
        <h5 class="mb-1"><feather-icon icon="GridIcon" size='15' /> Kalender Penjadwalan Penggunaan Alat Bersama</h5>
        <b-row>
          <b-col md="6">
            <b-form-group label="Jenis Alat Produksi" label-for="basicInput">
              <b-form-select v-model="id_alat_produksi" :options="listAlat.alat" size="sm" @input="calender_list()" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Status Peminjaman" label-for="basicInput">
              <b-form-select v-model="status" :options="listAlat.status_pengajuan" size="sm" @input="calender_list()" />
            </b-form-group>
          </b-col>

        </b-row>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-xl-12">
        <div class="card card-statistics"><!----><!---->
          <div class="card-body statistics-body">
            <b-row>
              <b-col md="7">
                <calender :events="events"></calender>
              </b-col>
              <b-col md="5"><tabel-peminjaman :rs_data="rs_data"></tabel-peminjaman></b-col>
            </b-row>

          </div><!----><!---->

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from '@/config/Axios';
import { BFormInput, BRow, BCol, BFormGroup, BFormSelect, BCard, BCardBody } from 'bootstrap-vue'
import TabelPeminjaman from '@/views/modul/admin/jadwal_penggunaan_alat/tabelPeminjaman.vue';
import calender from '@/views/modul/admin/jadwal_penggunaan_alat/calender.vue';
// import TabelPeminjaman from './tabelPeminjaman.vue';
export default {
  data() {
    return {
      events: [],
      rs_data: [],
      selected: null,
      listAlat: {},
      status_pengajuan: {},
      alat: {},
      status: '',
      id_alat_produksi: '',
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Simple Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true },
      ],
    }
  },
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormSelect,
    BCard, BCardBody, calender,
    TabelPeminjaman,
  },
  mounted() {
    this.calender_list();
    this.get_listAlat();
    this.get_datalist();
  },
  methods: {
    async calender_list() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/jadwal/calender_list',
        data: {
          status: self.status,
          id_alat_produksi: self.id_alat_produksi

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.events = response.data.result;
          self.get_datalist();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async get_datalist() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/jadwal/datalist',
        data: {
          status: self.status,
          id_alat_produksi: self.id_alat_produksi

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },


    async get_listAlat() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/jadwal/alat_produksi',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.listAlat = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  }
}
</script>

<style></style>